
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRomanRegular.ttf"); font-family : "Rubik"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RalewayBold.ttf"); font-family : "Raleway"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikSemiBold.ttf"); font-family : "Rubik"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RalewayRomanBold.ttf"); font-family : "Raleway"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsBold.ttf"); font-family : "Poppins"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikRegular.ttf"); font-family : "Rubik"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RalewayRomanRegular.ttf"); font-family : "Raleway"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikBold.ttf"); font-family : "Rubik"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RalewayRomanSemiBold.ttf"); font-family : "Raleway"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RubikMedium.ttf"); font-family : "Rubik"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PollerOneRegular.ttf"); font-family : "Poller One"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RalewaySemiBold.ttf"); font-family : "Raleway"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PlusJakartaSansRomanSemiBold.ttf"); font-family : "Plus Jakarta Sans"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RalewayRomanMedium.ttf"); font-family : "Raleway"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/JosefinSansRomanBold.ttf"); font-family : "Josefin Sans"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsMedium.ttf"); font-family : "Poppins"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsItalic.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/JosefinSansRomanSemiBold.ttf"); font-family : "Josefin Sans"; font-weight : 600;  }