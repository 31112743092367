.nav-link:hover {
    /* Add your desired hover effects here */
    color: #007bff; /* Change to the color you want on hover */
    text-decoration: underline; /* You can change this to other styles like 'none' if you don't want underline */
  }

  .product-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    transition: box-shadow 0.1s ease; /* Optional: Add a smooth transition effect */
  }
  
  .product-card:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2); /* Adjust the values as needed */
  }
  
  .close-icon {
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
    color: #e50c0c; /* Adjust the color as needed */
  }

  .sorting-label {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .sorting-label:hover {
    background-color: #f0f0f0; /* Change the color on hover */
  }
  
  .sorting-label input {
    margin-right: 8px; /* Adjust the spacing between the radio button and text */
  }

  .custom-login-button {
    background-color: #2196F3;
    cursor: pointer;
    font-weight: bold;
    padding: 19px;
    border-radius: 28px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .custom-login-button:hover {
    background-color: #1565c0; /* Change the color on hover */
  }
  
  .custom-login-button:active {
    background-color: #0d47a1; /* Change the color on click */
  }
  
  
  .custom-otp-button {
    background-color: #2196F3;
    cursor: pointer;
    font-weight: bold;
    padding: 19px;
    padding-right: 50px;
    padding-left: 50px;
    border-radius: 28px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    /* width: "100px"; */
    transition: background-color 0.3s ease;
  }
  
  .custom-otp-button:hover {
    background-color: #1565c0; /* Change the color on hover */
  }
  
  .custom-otp-button:active {
    background-color: #0d47a1; /* Change the color on click */
  }


  
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .desktop-only {
      display: none;
    }
  }
  
  