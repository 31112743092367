:root { 
--black_900_7f:#04080f7f; 
--gray_53:#f8f8f8; 
--deep_purple_500:#5847ca; 
--red_300:#d96d6d; 
--light_blue_600:#009ee3; 
--light_green_300:#a7bd83; 
--blue_A200:#4285f4; 
--red_500:#ea4335; 
--black_900_3f:#0000003f; 
--green_600:#34a853; 
--gray_50:#fef9f4; 
--black_900_87:#00020187; 
--teal_300:#56a1ad; 
--black_900:#000201; 
--gray_500_87:#8f8f8f87; 
--pink_700:#ba4164; 
--deep_orange_A400:#ff1616; 
--yellow_100:#fee3ca; 
--gray_600:#808080; 
--gray_700:#5f6368; 
--gray_500:#8f8f8f; 
--amber_500:#fbbc04; 
--red_A200:#ff4f4f; 
--gray_900:#100d22; 
--bluegray_100:#d9d9d9; 
--gray_200:#ededed; 
--orange_400:#ff9432; 
--gray_201:#e7e7e7; 
--orange_200:#fece68; 
--gray_100:#f6f6f6; 
--bluegray_900:#23292e; 
--bluegray_801:#274b53; 
--bluegray_800:#265e55; 
--gray_50_a3:#f8f8f8a3; 
--orange_50:#feefe0; 
--black_900_7f1:#0000007f; 
--bluegray_400:#878690; 
--indigo_900:#113984; 
--indigo_901:#172c70; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--bluegray_902:#343434; 
--bluegray_901:#273032; 
}